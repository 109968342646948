<template>
  <div>
    <div
      v-if="notifications && notifications.length"
      class="mt-2 mt-md-4 mb-4 mx-2 mx-md-4 notification-card"
    >
      <div
        v-for="(notification, index) in notifications"
        :key="notification._id"
        :class="{ 'unread-message': !notification.isRead }"
      >
        <p class="pr-4 pt-3">
          <a :href="`/profile/${notification.userId}`"
            ><strong>{{ notification.userName }}</strong></a
          >
          {{ notification.message }}
          <a :href="`/post/${notification.articleId}`"
            ><strong>{{ notification.article }}</strong></a
          >, {{ notification.time | timeFilterComment }}
        </p>
        <hr class="mb-0" v-if="index !== notifications.length - 1" />
      </div>
    </div>
    <span v-else>No new Notifications</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      notifications: [],
      limit: 10,
      offset: 0,
      openGate: true,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    isUnreadMessage() {
      return this.notifications?.some((item) => item.isRead === false);
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  async mounted() {
    try {
      this.notifications = await this.getNotifications({
        id: this.userDetails.id,
        isUnread: true,
      });

      let res;

      this.offset = this.notifications.length;

      if (this.notifications.length < this.limit) {
        res = await this.getNotifications({
          id: this.userDetails.id,
          limit: this.limit - this.notifications.length,
          offset: this.offset,
        });
      }

      this.notifications.push(...res);
    } catch (e) {
      if (e.response.status === 401) {
        this.signOut();
      }
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(["readAllNotifications", "getNotifications", "signOut"]),

    readNotifications() {
      if (this.isUnreadMessage) {
        this.readAllNotifications({ id: this.userDetails.id });
        this.notifications.forEach((item) => (item.isRead = true));
      }
    },

    async handleScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 1;

      if (this.openGate && bottomOfWindow) {
        this.showSpinner = true;
        this.openGate = false;

        this.offset = this.offset + 10;

        try {
          let res = await this.getNotifications({
            id: this.userDetails.id,
            limit: this.limit,
            offset: this.offset,
          });

          if (res.length) this.openGate = true;

          this.notifications.push(...res);
        } catch (e) {
          console.error(e);
          this.openGate = true;
          if (e.response.status === 401) {
            this.signOut();
          }
        } finally {
          this.showSpinner = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.notification-card {
  background: white;
  border: 1px solid #2c3e50;
}
.unread-message {
  background-color: #dde2ea;
}
a {
  color: #544a71;
}
</style>
